import "./slider.scss";
import Glide, {
    Autoplay,
    Breakpoints,
    Controls,
    Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import { loadBloc } from "../../../assets/js/modules/utils";

(($) => {
    let options = {
        type: "carousel",
        autoplay: 4000,
        gap: 0,
        startAt: 1,
        breakpoints: {
            9999: {
                perView: 3,
                focusAt: "center",
            },
            1023: {
                perView: 2,
                focusAt: 0,
                startAt: 0,
            },
            767: {
                perView: 1,
                autoplay: false,
            },
        },
    };

    function actusSlider() {
        let slider = null;
        const sliderEl = $(".actus.glide"),
            bullets = sliderEl.find(".glide__bullets"),
            slides = sliderEl.find(".glide__slide"),
            slidesCount = slides.length;

        let bkp = Number(
            Object.keys(options.breakpoints).find(
                (bkp) => window.innerWidth <= bkp
            )
        );
        let bkpOptions = options.breakpoints[bkp];

        if (slidesCount < bkpOptions.perView) {
            options.breakpoints[bkp].perView = slidesCount;
        }

        if (slidesCount === bkpOptions.perView) {
            bullets.addClass("hidden");
            if (bkp === 9999 && slidesCount > 2) {
                $(slides[1]).addClass("glide__slide--active");
            }
            return;
        }

        slider = new Glide(sliderEl[0], options);
        slider.on("mount.after", function () {
            sliderEl.addClass("mounted");
        });
        slider.mount({
            Autoplay,
            Breakpoints,
            Controls,
            Swipe,
        });
    }
    loadBloc(actusSlider);
})(jQuery);
