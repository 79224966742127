const loadBloc = (callback) => {
    let callbackName = callback.prototype.constructor.name;
    if (jQuery("body").hasClass("wp-admin")) {
        jQuery(document).on(callbackName, callback);
    } else {
        jQuery(document).ready(callback);
    }
};

export { loadBloc };
